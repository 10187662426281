import React from "react"

export default function FeaturesTextToPay() {

  return (
    <div className="section no-top-bottom">
      <div className="container columns">
        <div className="row clearfix">
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Reduce Credit Card Fees</span> &mdash; The average credit card processing rate for transactions keyed into a POS system is 3.5% +.15 cents. Save up to an entire percent per order by using Text to Pay.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Secure Transactions</span> &mdash; Sharing credit card details over the phone is not PCI-compliant or chargeback protected. Text to Pay offers industry leading payment security and peace of mind for both operators and customers.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Instant Promotions</span> &mdash; Include coupons for future orders or feature new menu items to increase repeat business.
                </p>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="feature">
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M19 0h-14c-2.762 0-5 2.239-5 5v14c0 2.761 2.238 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-8.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg>
                </span>
                <p className="text-light-grey no-top-bottom">
                  <span className="text-white font-weight-700">Enable Mobile Wallet Payments</span> &mdash; Instantly accept Apple Pay and Google Pay for all takeout orders without adding extra hardware or fees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

