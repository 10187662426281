import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

// Import Images
import Logo from '../images/rooam-icon.svg'

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import FeaturesTextToPay from "../components/features-text-to-pay"
import TextToPaySteps from "../components/text-to-pay-steps"

const Text_To_Pay = () => {
  const data = useStaticQuery(graphql`
    query {
      rooamTextToPay: file(relativePath: { eq: "phones/Rooam-Text-To-Pay.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      rooamPay: file(relativePath: { eq: "phones/Rooam-Pay-Check-Full.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      paytronix: file(relativePath: { eq: "integrations/paytronix.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      fishbowl: file(relativePath: { eq: "integrations/fishbowl.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Rooam: Text to Pay - Accept payments from phone orders" description="Reduce processing costs and fraud risk while digitally engaging customers in an entirely new way." />
      <div className="top-message text-center background-dark-grey">
        <p className="no-top-bottom small font-weight-400 text-light-grey">Text to Pay pairs with our web solution, <Link to="/pay/" className="text-link-white font-weight-600">Rooam Pay</Link></p>
      </div>
      <div className="section no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top">Text to Pay</h1>
          <p className="lead text-light-grey no-bottom">A revolutionary solution to the difficulties of accepting payments from phone orders. By adding a single button in their existing POS system, restaurants can reduce processing costs and fraud risk while digitally engaging customers in an entirely new way.</p>
        </div>
      </div>

      <div className="section no-bottom">
        <div className="section no-top-bottom">
          <div className="container text-center">
            <h3 className="more text-white no-top">How it works</h3>
          </div>
        </div>
        <TextToPaySteps/>
      </div>

      <div className="section no-bottom">
        <div className="container"> 
          <div className="row clearfix">
            <div className="column break-full sticky">
              <div className="padding more no-top float-middle" style={{ maxWidth: 390 }}>
                <Img
                  fluid={data.rooamTextToPay.childImageSharp.fluid}
                  alt="Rooam Pay Split The Check Screen"
                  loading="eager"
                />
              </div>
            </div>
            <div className="column break-full">
              <div className="padding more float-middle" style={{ maxWidth: 400 }}>
                <h2 className="text-grey font-weight-400">
                  <span className="text-white font-weight-700">Custom Messages and Digital Links</span> &mdash; Never miss an opportunity to convert one-time phone orders into loyal customers.
                </h2>
                <ul className="extras no-bottom">
                  <li>
                    <span className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z"/></svg>
                    </span>
                    <p className="text-grey no-top-bottom"><span className="font-weight-600 text-white">Loyalty Signups.</span> Send guests directly to your website to discover your brand and sign up for promotions.</p>
                  </li>
                  <li class="no-bottom">
                    <span className="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z"/></svg>
                    </span>
                    <p className="text-grey no-top-bottom"><span className="font-weight-600 text-white">Gift Card Purchases.</span> Sell gift cards directly to guests that were previously difficult to reach through traditional marketing.</p>
                  </li>
                </ul>
                <div className="section less no-top-bottom text-center" style={{ maxWidth: 370 }}>
                  <div className="row clearfix">
                    <div className="column">
                      <Img
                        fluid={data.paytronix.childImageSharp.fluid}
                        alt="Paytronix logo"
                        loading="eager"
                      />
                    </div>
                    <div className="column">
                      <Img
                        fluid={data.fishbowl.childImageSharp.fluid}
                        alt="Fishbowl logo"
                        loading="eager"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <FeaturesTextToPay/>
      </div>

      <div className="section no-top">
        <div className="container text-center">
          <div className="peak-wrap no-top">
            <div className="icon logo">
              <Link to="/"><img src={Logo} alt="Rooam Logo" /></Link>
            </div>
          </div>
          <h2 className="no-top-bottom">
            <span className="text-white">Rooam is built for your business.</span><br/>
            <span className="text-grey">Go live today.</span>
          </h2>
          <Link to="/request-demo/" className="btn light no-bottom-margin">Request Demo</Link>
          <a className="btn dark no-bottom-margin" href="mailto:business@rooam.co" target="_blank" rel="noopener noreferrer">
            Contact Sales
          </a>
        </div>
      </div>

    </Layout>
  )
}

export default Text_To_Pay
