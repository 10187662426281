import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

export default function TextToPaySteps() {

  const data = useStaticQuery(graphql`
    query {
      step_one: file(relativePath: { eq: "steps/text-to-pay/text-to-pay-step1.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      step_two: file(relativePath: { eq: "steps/text-to-pay/text-to-pay-step2.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      step_three: file(relativePath: { eq: "steps/text-to-pay/text-to-pay-step3.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      step_four: file(relativePath: { eq: "steps/text-to-pay/text-to-pay-step4.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      step_five: file(relativePath: { eq: "steps/text-to-pay/text-to-pay-step5.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      },
      step_six: file(relativePath: { eq: "steps/text-to-pay/text-to-pay-step6.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <div className="section no-top-bottom">
      <div className="container columns">
        <div className="row clearfix">
          <div className="column break-full">
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <div className="step-img">
                    <Img
                      fluid={data.step_one.childImageSharp.fluid}
                      alt="Text to Pay Step 1"
                      loading="eager"
                    />
                  </div>
                  <div className="step">
                    <div className="number-circle">
                      <span className="number">1</span>
                    </div>
                    <p className="small text-white font-weight-500 no-top-bottom">Hostess enters the order into POS and clicks “TEXT TO PAY”</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <div className="step-img">
                    <Img
                      fluid={data.step_two.childImageSharp.fluid}
                      alt="Text to Pay Step 2"
                      loading="eager"
                    />
                  </div>
                  <div className="step">
                    <div className="number-circle">
                      <span className="number">2</span>
                    </div>
                    <p className="small text-white font-weight-500 no-top-bottom">Hostess enters guests phone number, then confirms</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="column break-full">
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <div className="step-img">
                    <Img
                      fluid={data.step_three.childImageSharp.fluid}
                      alt="Text to Pay Step 3"
                      loading="eager"
                    />
                  </div>
                  <div className="step">
                    <div className="number-circle">
                      <span className="number">3</span>
                    </div>
                    <p className="small text-white font-weight-500 no-top-bottom">Guest receives a text message with payment link and custom message</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <div className="step-img">
                    <Img
                      fluid={data.step_four.childImageSharp.fluid}
                      alt="Text to Pay Step 4"
                      loading="eager"
                    />
                  </div>
                  <div className="step">
                    <div className="number-circle">
                      <span className="number">4</span>
                    </div>
                    <p className="small text-white font-weight-500 no-top-bottom">Guest reviews their check in full and selects the tip amount</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="column break-full">
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <div className="step-img">
                    <Img
                      fluid={data.step_five.childImageSharp.fluid}
                      alt="Text to Pay Step 5"
                      loading="eager"
                    />
                  </div>
                  <div className="step">
                    <div className="number-circle">
                      <span className="number">5</span>
                    </div>
                    <p className="small text-white font-weight-500 no-top-bottom">Guest enters payment information and completes payment</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column break-full">
            <div className="padding">
              <div className="box background-dark-grey">
                <div className="box-padding">
                  <div className="step-img">
                    <Img
                      fluid={data.step_six.childImageSharp.fluid}
                      alt="Text to Pay Step 6"
                      loading="eager"
                    />
                  </div>
                  <div className="step">
                    <div className="number-circle">
                      <span className="number">6</span>
                    </div>
                    <p className="small text-white font-weight-500 no-top-bottom">Once paid, check is removed from the POS screen and guest can receive order</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

